<template>
  <div>
    <!-- Task Card Header -->
    <!-- TODO: uncomment this if material now have overview -->
    <!-- <b-card class="card mb-1" title="Materi Kelas">
      <div class="mt-2">
        <b-row>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Nama Materi</label>
            <b-form-input
              id="classroom-name"
              v-model="formData.classroomName"
              placeholder="Masukkan Nama Kelas"
              :state="formErrors.classroomName.state"
            />
            <b-form-invalid-feedback v-show="!formErrors.classroomName.state">
              {{ formErrors.classroomName.message }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Deskripsi Materi</label>
            <b-form-textarea
              id="textarea-no-resize"
              placeholder="Deskripsi Kelas"
              rows="3"
              no-resize
              v-model="formData.classroomDescription"
              :state="formErrors.classroomDescription.state"
            />
            <b-form-invalid-feedback
              v-show="!formErrors.classroomDescription.state"
            >
              {{ formErrors.classroomDescription.message }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <div>
          <b-button variant="primary" class="float-right " @click="submitForm">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              <i class="ti ti-device-floppy small-gap-right"> </i>
              Simpan
            </div>
          </b-button>

          <b-button variant="warning" class="float-right small-gap-right">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              <i class="ti ti-plus small-gap-right"> </i>
              Tambah Materi
            </div>
          </b-button>
        </div>
      </div>
    </b-card> -->

    <b-card class="min-height-screen">
      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex align-items-center ">
          <i class="text-primary ti ti-book-2 small-gap-right" />
          <h4 class="text-primary mb-0">
            Materi Kelas
          </h4>
        </div>

        <!-- CTA Button -->
        <div>
          <b-button
            variant="outline-danger"
            class="small-gap-right"
          >
            <div>
              <i class="ti ti-trash small-gap-right" />
              Hapus Semua
            </div>
          </b-button>
          <b-button
            variant="primary"
            :to="{ name: checkAuthorizeRole('classroom-detail-material-add') }"
          >
            <div>
              <i class="ti ti-plus small-gap-right" />
              Tambah
            </div>
          </b-button>
        </div>
      </div>

      <!-- Material Data -->
      <div
        v-show="!isLoading && classroomContentMaterials.length !== 0"
        class="vertical-scrollable"
      >
        <b-row>
          <!-- Material List -->
          <b-col
            v-for="material in classroomContentMaterials"
            :key="material"
            xl="4"
            lg="6"
            md="6"
          >
            <!-- Video -->
            <b-card
              v-show="material.video_url != null"
              :title="material.title"
              class="border-light-secondary"
              bg-variant="transparent"
            >
              <div class="img-overlay text-center">
                <b-card-img
                  class="preview-material"
                  src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  alt="Image"
                />
                <b-avatar
                  class="preview-icon bg-white"
                  variant="light-primary"
                >
                  <i class="card-icon ti ti-player-play" />
                </b-avatar>
              </div>
              <div class="py-1 mt-1 border-top d-flex justify-content-between">
                <b-button
                  size="sm"
                  variant="outline-warning"
                  class="d-flex align-items-center"
                >
                  <i class="ti ti-replace small-gap-right" />
                  Reupload
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="d-flex align-items-center"
                >
                  <i class="ti ti-trash small-gap-right" />
                  Hapus
                </b-button>

                <b-button
                  size="sm"
                  variant="outline-info"
                  class="d-flex align-items-center"
                >
                  <i class="ti ti-download small-gap-right" />
                  Download
                </b-button>
              </div>
            </b-card>

            <!-- File -->
            <b-card
              v-show="material.file != null"
              class="border-light-secondary"
              :title="material.title"
              bg-variant="transparent"
            >
              <div class="img-overlay text-center">
                <b-card-img
                  class="preview-material"
                  :src="baseMaterialBgImage"
                  alt="Image"
                />
                <b-badge
                  class="preview-icon bg-white py-1 px-2"
                  :variant="`light-${fileType(getExtension(material.file))}`"
                >
                  <span class="card-icon ">
                    .{{ getExtension(material.file) }}
                  </span>
                </b-badge>
              </div>
              <div class="py-1 mt-1 border-top d-flex justify-content-between">
                <b-button
                  size="sm"
                  variant="outline-warning"
                  class="d-flex align-items-center"
                >
                  <i class="ti ti-replace small-gap-right" />
                  Reupload
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="d-flex align-items-center"
                >
                  <i class="ti ti-trash small-gap-right" />
                  Hapus
                </b-button>

                <b-button
                  size="sm"
                  variant="outline-info"
                  class="d-flex align-items-center"
                  :href="material.file"
                  target="_blank"
                >
                  <i class="ti ti-download small-gap-right" />
                  Download
                </b-button>
              </div>
            </b-card>
          </b-col>

          <!-- Sample Video -->
          <b-col
            xl="4"
            lg="6"
            md="6"
          >
            <b-card
              v-show="classroomContentMaterials.length > 0"
              class="border-light-secondary"
              title="Sample Materi Video"
              bg-variant="transparent"
            >
              <div class="img-overlay text-center">
                <b-card-img
                  class="preview-material"
                  :src="sampleVideoMaterialBgImage"
                  alt="Image"
                />
                <b-avatar
                  v-b-modal.modal-center
                  class="preview-icon bg-white"
                  variant="light-primary"
                >
                  <i class="card-icon ti ti-player-play " />
                </b-avatar>
              </div>
              <div class="py-1 mt-1 border-top d-flex justify-content-between">
                <b-button
                  size="sm"
                  variant="outline-warning"
                  class="d-flex align-items-center"
                >
                  <i class="ti ti-replace small-gap-right" />
                  Reupload
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="d-flex align-items-center"
                >
                  <i class="ti ti-trash small-gap-right" />
                  Hapus
                </b-button>

                <b-button
                  size="sm"
                  variant="outline-info"
                  class="d-flex align-items-center"
                  :href="sampleVideoMaterial"
                  target="_blank"
                >
                  <i class="ti ti-download small-gap-right" />
                  Download
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>

      <!-- Empty State -->
      <div v-show="!isLoading && classroomContentMaterials.length === 0">
        <b-row>
          <b-col class="d-flex justify-content-center mt-4">
            <div class="text-center pt-2">
              <b-img
                class="illustration-small"
                fluid
                :src="emptyStateImg"
              />
              <p class="mb-2">
                Belum Ada Materi Kelas
              </p>
              <b-button
                variant="primary"
                class="mb-1 btn-sm-block"
                :to="{
                  name: checkAuthorizeRole('classroom-detail-material-add'),
                }"
              >Tambah Materi Kelas</b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Loading Material -->
      <div
        v-if="isLoading"
        class="d-flex justify-content-center mt-4"
      >
        <div class="text-center mt-4">
          <b-spinner
            variant="primary"
            class="mr-1"
          />
          <p class="mt-1">
            Memuat Daftar Materi
          </p>
        </div>
      </div>
    </b-card>

    <!-- Preview Modal -->
    <div>
      <b-modal
        id="modal-center"
        centered
        size="lg"
        title="Preview"
        ok-only
        ok-title="Tutup"
      >
        <b-card-text>
          <video
            class="rounded"
            autoplay
            controls
            width="100%"
            :src="sampleVideoMaterial"
          />
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>

<script>
// ** Third Party Components
import axios from 'axios'

// Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BBadge,
  BCardImg,
  BImg,
  BAvatar,
  BSpinner,
} from 'bootstrap-vue'

// Utils
import { checkAuthorizeRole } from '@/auth/utils'
import { localizeISODate, fileType } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BCardImg,
    BImg,
    BAvatar,
    BSpinner,
  },
  data() {
    return {
      // state
      isLoading: true,

      // assets
      baseMaterialBgImage: require('@/assets/images/component/material/material-bg.png'),
      sampleVideoMaterialBgImage: require('@/assets/media/sample/video/sample-video.png'),
      sampleVideoMaterial: require('@/assets/media/sample/video/sample-video.mp4'),
      emptyStateImg: require('@/assets/images/illustration/no-connection.svg'),

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      classroomContentMaterials: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    const { breadcrumb } = to.meta

    to.meta.breadcrumb[breadcrumb.length - 2].params = { id: to.params.id }
    next()
  },
  async mounted() {
    const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`
    const url = `${baseUrl}/classrooms/${this.classroomId}`
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'multipart/form-data',
      },
    }

    await axios.get(url, config).then(response => {
      const materialDataByContentId = response.data.data.class_contents.filter(
        content => content.id === this.contentId,
      )

      this.classroomContentMaterials = [...materialDataByContentId[0].materials]
      this.isLoading = false
    })
  },
  methods: {
    checkAuthorizeRole,
    localizeISODate,
    fileType,
    getExtension(filename) {
      return filename.split('.').pop()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.line-height-small {
  line-height: 1.5rem;
}

.small-gap-right {
  margin-right: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.preview-material {
  height: 160px;
  max-height: 200px;
  object-fit: fill;
}

.preview-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  font-size: 2rem;
}

.vertical-scrollable {
  position: relative;
  padding-right: 15px;
  height: calc(100vh - 330px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.illustration-small {
  height: 300px;
  object-fit: cover;
}

.min-height-screen {
  min-height: calc(100vh - 190px);
}
</style>
